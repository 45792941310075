import { Fragment } from 'react'
import { Route, Routes } from 'react-router-dom'
import PrivateRoute from '../../../routing/PrivateRoute'
import AdminRedirect from '../../../routing/AdminRedirect'
import AdminHome from '../Home/Index'
import UsersManagement from '../Users/Index'
import RolesManagement from '../Roles/Index'
import PermissionsManagement from '../Permissions/Index'
import NotFound from '../../../components/Errors/NotFound/Index'
import ShowUser from '../Users/ShowUser/Index'
import UpdateUser from '../Users/UpdateUser/Index'
import AdminProjects from '../Projects/Index'
import AdminPlatforms from '../Platforms/Index'
import CreateUser from '../Users/CreateUser/Index'
import LeadsAdminPage from '../Leads/Index'
import AdminMessages from '../Messages/Index'
import AdminShowProjects from '../Projects/ShowProjects/Index'

const AdminRoutes = () => {
  return (
    <Fragment>
      <Routes>
        {/* Buyer Routes */}
        <Route
          path="/"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <AdminHome />
              </PrivateRoute>
            </AdminRedirect>
          }
        />

        <Route
          path="/messages"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <AdminMessages />
              </PrivateRoute>
            </AdminRedirect>
          }
        />
        {/* Admin Project Routes */}
        <Route
          path="/projects"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <AdminProjects />
              </PrivateRoute>
            </AdminRedirect>
          }
        />
        <Route
          path="/projects/:id"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <AdminShowProjects />
              </PrivateRoute>
            </AdminRedirect>
          }
        />

        {/* Admin Platforms Routes */}
        <Route
          path="/platforms"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <AdminPlatforms />
              </PrivateRoute>
            </AdminRedirect>
          }
        />

        <Route
          path="/users"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <UsersManagement />
              </PrivateRoute>
            </AdminRedirect>
          }
        />
        <Route
          path="/users/new"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <CreateUser />
              </PrivateRoute>
            </AdminRedirect>
          }
        />
        <Route
          path="/users/:id"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <ShowUser />
              </PrivateRoute>
            </AdminRedirect>
          }
        />
        <Route
          path="/users/:id/edit"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <UpdateUser />
              </PrivateRoute>
            </AdminRedirect>
          }
        />
        <Route
          path="/roles"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <RolesManagement />
              </PrivateRoute>
            </AdminRedirect>
          }
        />

        <Route
          path="/permissions"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <PermissionsManagement />
              </PrivateRoute>
            </AdminRedirect>
          }
        />

        <Route
          path="/leads"
          element={
            <AdminRedirect>
              <PrivateRoute>
                <LeadsAdminPage />
              </PrivateRoute>
            </AdminRedirect>
          }
        />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Fragment>
  )
}
export default AdminRoutes
